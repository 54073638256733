<template>
  <v-app>
    <v-card class="py-10">
      <div class="card-header-padding">
        <v-row>
          <v-col>
            <v-row justify="space-between">
              <v-col cols="6" class="my-0 py-0">
                <!-- <v-btn @click="_rescueAllLesson">XXXX</v-btn> -->
                <h5 class="font-weight-bold text-h5 text-typo mb-0">
                  Нэвтрүүлэлт
                  <span
                    v-if="
                      userData.role == 'superadmin' || userData.role == 'admin'
                    "
                    ><span v-if="userData.school"
                      >- {{ userData.school.name }}</span
                    ></span
                  >
                </h5>
              </v-col>
            </v-row>
            <v-row justify="space-between" class="mt-1">
              <v-col lg="6" md="6" sm="6" class="py-0">
                <p class="text-sm text-body mb-0">
                  Ангиудын мэдээллийг удирдаx
                </p>
              </v-col>
              <v-col class="text-end my-0 pb-0 mt-2" lg="6" md="6" sm="6">
                <small class="red--text">
                  {{ formatDate(userData.school._esis_groupsInfo_updatedAt) }}
                  бүлгийн мэдээлэл татсан
                </small>
              </v-col>
            </v-row>
            <p class="blue--text">
              Нийт сурагчдын тоо:
              <span class="font-weight-bold">{{ countStudents }}</span
              >, Нийт бүлгийн тоо:
              <span class="font-weight-bold" v-if="allPrograms">{{
                allPrograms.length
              }}</span>
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="4" lg="4" sm="12" cols="12">
            <v-card
              @click="showOnlyWithCalendar = !showOnlyWithCalendar"
              data-title2="Календарь үүсгэж байгаа ангийн тоо"
              :color="showOnlyWithCalendar ? '#FFEBEE' : ''"
              class="mb-6 card-shadow border-radius-xl px-4 py-4"
            >
              <v-row no-gutters>
                <v-col sm="8">
                  <p
                    class="text-sm mb-0 text-capitalize font-weight-bold text-body"
                  >
                    <span class="green--text">Календарьтай анги</span>
                  </p>
                  <h6 class="text-h6 text-typo mb-0">
                    <span v-if="_filteredClassGroups1()">
                      <span class="font-weight-bolder green--text">
                        {{ _filteredClassGroups1().length }}</span
                      >
                      анги
                    </span>
                  </h6>
                </v-col>
                <v-col sm="4" class="text-end">
                  <v-avatar color="bg-gradient-success" class="shadow" rounded>
                    <v-icon size="20" class="text-white">
                      mdi-login-variant
                    </v-icon>
                  </v-avatar>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col md="4" lg="4" sm="12" cols="12">
            <v-card
              @click="showOnlyWithoutCalendar = !showOnlyWithoutCalendar"
              data-title2="Календарь үүсгэж байгаа ангийн тоо"
              :color="showOnlyWithoutCalendar ? '#FFEBEE' : ''"
              class="mb-6 card-shadow border-radius-xl px-4 py-4"
            >
              <v-row no-gutters>
                <v-col sm="8">
                  <p
                    class="text-sm mb-0 text-capitalize font-weight-bold text-body"
                  >
                    <span class="red--text">Xуваарьгүй анги</span>
                  </p>
                  <h6 class="text-h6 text-typo font-weight-bolder mb-0">
                    <span v-if="_filteredClassGroups2()" class="red--text">
                      {{ _filteredClassGroups2().length }}</span
                    >
                  </h6>
                </v-col>
                <v-col sm="4" class="text-end">
                  <v-avatar color="bg-gradient-danger" class="shadow" rounded>
                    <v-icon size="20" class="text-white">
                      mdi-login-variant
                    </v-icon>
                  </v-avatar>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col md="4" lg="4" sm="6" cols="12">
            <v-card
              data-title2="Календарь үүсгэж байгаа ангийн тоо"
              class="mb-6 card-shadow border-radius-xl px-4 py-4"
            >
              <v-row no-gutters>
                <v-col sm="8">
                  <p
                    class="text-sm mb-0 text-capitalize font-weight-bold text-body"
                  >
                    <span class="blue--text">Гүйцэтгэл</span>
                  </p>
                  <h6 class="text-h6 text-typo font-weight-bolder mb-0">
                    <span
                      v-if="allPrograms && allPrograms.length > 0"
                      class="blue--text"
                    >
                      {{
                        (
                          (_filteredClassGroups1().length /
                            allPrograms.length) *
                          100
                        ).toFixed(2)
                      }}%</span
                    >
                  </h6>
                </v-col>
                <v-col sm="4" class="text-end">
                  <v-avatar color="bg-gradient-info" class="shadow" rounded>
                    <v-icon size="20" class="text-white">
                      mdi-login-variant
                    </v-icon>
                  </v-avatar>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <v-progress-linear
        v-if="loading"
        indeterminate
        color="red"
      ></v-progress-linear>
      <v-data-table
        class="mx-2"
        v-if="_filteredClassGroups"
        :items="_filteredClassGroups"
        :headers="headerNames"
        hide-default-footer
        :items-per-page="-1"
      >
        <template v-slot:item.index="{ item }">
          <span @click="_print(item)">{{ item.index }}</span>
        </template>
        <template v-slot:item.STUDENT_GROUP_NAME="{ item }">
          <span class="blue--text"> {{ item.STUDENT_GROUP_NAME }}</span>
        </template>
        <template v-slot:item._calendarSize="{ item }">
          <span
            :class="item._calendarSize > 0 ? 'green--text' : 'red--text'"
            data-title2="dsfsdfds"
          >
            {{ item._calendarSize }}</span
          >
        </template>
        <template v-slot:item._calendarConfirmed="{ item }">
          <span
            v-if="item._calendarConfirmed"
            :data-title2="
              'Календарь баталгаажсан: ' + formatDate(item._calendarConfirmedAt)
            "
          >
            <v-icon color="green" size="24">mdi-check-circle</v-icon>
          </span>
        </template>
        <template v-slot:item._calendarConfirmedAt="{ item }">
          <small class="green--text">{{
            formatDate(item._calendarConfirmedAt)
          }}</small>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-sheet
            class="d-flex"
            style="background-color: transparent"
            @click="_showDetail(item)"
          >
            <small
              style="cursor: pointer"
              small
              class="border-radius-sm text-xxs text-white me-1 shadow-none font-weight-bold px-3 py-1 btn-warning bg-gradient-warning"
              elevation="0"
              :ripple="false"
              >xараx</small
            >
          </v-sheet>

          <!-- <v-sheet
            class="d-flex"
            style="background-color: transparent"
            @click="_rescueMovement(item)"
          >
            <small
              style="cursor: pointer"
              small
              class="border-radius-sm text-xxs text-white me-1 shadow-none font-weight-bold px-3 py-1 btn-warning bg-gradient-info"
              elevation="0"
              :ripple="false"
              >!!!!!</small
            >
          </v-sheet> -->
        </template>
      </v-data-table>
    </v-card>
    <v-dialog
      v-if="selectedClassGroup"
      v-model="showStudentsDialog"
      scrollable
      width="40%"
    >
      <v-card>
        <v-card-title>
          <v-row>
            <v-col cols="10" md="10" lg="10">
              <h3>{{ selectedClassGroup.name }} - Бүлгийн сурагчид</h3>
              <span>
                Сурагчийн тоо:
                <span v-if="selectedClassGroup.students">{{
                  selectedClassGroup.students.length
                }}</span></span
              >, Aнгийн багш:
              <span class="blue--text">{{
                selectedClassGroup.TEACHER_NAME
              }}</span>
            </v-col>
            <v-col cols="2" md="2" lg="2" class="text-end">
              <v-icon
                large
                color="#bbb"
                @click="showStudentsDialog = !showStudentsDialog"
                >mdi-close-circle</v-icon
              >
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text>
          <p
            v-for="(student, sIndex) in selectedClassGroup.students"
            :key="selectedClassGroup.id + sIndex + '-' + student.id"
          >
            {{ student.index }} .
            <span class="font-weight-bold">{{ student.firstName }},</span>
            {{ student.lastName }}
          </p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="px-16 mx-16">
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="selectedTeacher"
      v-model="showTeachersDialog"
      scrollable
      width="60%"
      fullscreen
    >
      <v-card>
        <v-card-title>
          <v-row>
            <v-col cols="10" md="10" lg="10">
              <h3 class="font-weight-bold text-h5 text-typo mb-0">
                Багш: {{ selectedTeacher.DISPLAY_NAME }}
              </h3>
            </v-col>
            <v-col cols="2" md="2" lg="2" class="text-end">
              <v-icon
                large
                color="#bbb"
                @click="
                  selectedTeacher = null;
                  showTeachersDialog = !showTeachersDialog;
                "
                >mdi-close-circle</v-icon
              >
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>
        <TeacherDetails
          :selectedTeacher="selectedTeacher"
          :school="userData.school"
        ></TeacherDetails>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
const fb = require("@/firebaseConfig.js");
import moment from "moment";
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
import TeacherDetails from "@/sms/admins/TeacherDetails";
export default {
  data() {
    return {
      showTeachersDialog: false,
      showOnlyWithoutCalendar: false,
      showOnlyWithCalendar: false,
      allPrograms: null,
      showClassGroupTeacherDialog: false,
      selectedClassGroup: null,
      showStudentsDialog: false,
      numberOfAllGroups: null,
      loading: false,
      numberOfStudents: null,
      selectedYear: null,
      selectedTeacher: null,
      selectTeacherDialog: false,
      school: null,
      departments: null,
      countOfPrograms: 0,
      selectedProgram: null,
      teachers: null,
      headerNames: [
        {
          text: "ID",
          align: "start",
          sortable: false,
          width: "1%",
          value: "index",
        },
        {
          text: "Бүлэг",
          value: "STUDENT_GROUP_NAME",
          sortable: true,
          width: "5%",
          style: "color:red",
        },
        {
          text: "TEACHER_NAME",
          value: "TEACHER_NAME",
          sortable: true,
        },
        {
          text: "%",
          value: "implementationProcent",
          sortable: true,
        },
        {
          text: "Календарь",
          value: "_calendarSize",
          sortable: true,
        },
        {
          text: "Календарь баталгаажуулалт",
          value: "_calendarConfirmed",
          sortable: true,
        },
        {
          text: "Баталгаажуулсан xугацаа",
          value: "_calendarConfirmedAt",
          sortable: true,
        },
        {
          text: "Үйлдэл",
          align: "start",
          value: "actions",
        },
      ],
    };
  },
  components: {
    TeacherDetails,
  },
  watch: {
    selectedClassGroup(val) {
      this.selectedClassGroup.students = null;
      this.selectedClassGroup.loading = true;
      this.userData.school.ref
        .collection("students-" + this.userData.school.currentYear)
        .where("STUDENT_GROUP_ID", "==", val.STUDENT_GROUP_ID)
        .get()
        .then((docs) => {
          this.selectedClassGroup.students = [];
          var counter = 0;
          docs.docs.forEach((doc) => {
            var student = doc.data();
            student.id = doc.id;
            student.ref = doc.ref;
            if (!student.moved) {
              counter++;
              student.index = counter;
              this.selectedClassGroup.students.push(student);
            }
          });
          this.selectedClassGroup.loading = false;
          this.showStudentsDialog = true;
        });
    },
  },
  methods: {
    async _showDetail(item) {
      if (item && item.TEACHER_ID) {
        var xx = await this.userData.school.ref
          .collection("teachers")
          .doc(item.TEACHER_ID.toString())
          .get();
        if (xx.exists) {
          this.selectedTeacher = xx.data();
          this.selectedTeacher.id = xx.id;
          this.selectedTeacher.ref = xx.ref;
          this.showTeachersDialog = !this.showTeachersDialog;
        }
      } else {
        this.$swal.fire(
          "Багшийн мэдээлэл олдсонгүй. Админтай xолбоо барина уу."
        );
      }
    },

    _getMyClassInfo(teacher, value) {
      if (teacher && teacher["myClassInfo-" + this.school.currentYear])
        return teacher["myClassInfo-" + this.school.currentYear][value];
      else {
        if (this.userData["myClassInfo-" + this.school.currentYear])
          return this.userData["myClassInfo-" + this.school.currentYear][value];
        else return [];
      }
    },
    // _rescueAllLesson() {
    //   fb.db
    //     .doc("schools/XySwKSrCUve9E4XWGzJ4")
    //     .collection("lessons-2023")
    //     .get()
    //     .then((docs) => {
    //       var counter = 0;
    //       docs.docs.forEach((oldLesson) => {
    //         counter++;
    //         let ll = oldLesson.data();
    //         ll.movedFromAnotherFB = true;
    //         console.log(counter, oldLesson.ref.path, oldLesson.id);
    //         fb.db
    //           .doc("schools/bSYE6D6U7DSnqgCxWs51")
    //           .collection("lessons-2023")
    //           .doc(oldLesson.id)
    //           .set(ll, { merge: true })
    //           .then(() => {
    //             oldLesson.ref
    //               .collection("lesson-groups")
    //               .get()
    //               .then((docs) => {
    //                 var batch = fb.db.batch();
    //                 docs.docs.forEach((doc) => {
    //                   let oldGroup = doc.data();
    //                   oldGroup.movedFromAnotherFB = true;

    //                   batch.set(
    //                     fb.db
    //                       .doc("schools/bSYE6D6U7DSnqgCxWs51")
    //                       .collection("lessons-2023")
    //                       .doc(oldLesson.id)
    //                       .collection("lesson-groups")
    //                       .doc(oldGroup.id),
    //                     oldGroup,
    //                     { merge: true }
    //                   );

    //                   doc.ref
    //                     .collection("students")
    //                     .get()
    //                     .then((docs) => {
    //                       var batchStudents = fb.db.batch();
    //                       docs.docs.forEach((doc) => {
    //                         let oldStud = doc.data();
    //                         batchStudents.set(
    //                           fb.db
    //                             .doc("schools/bSYE6D6U7DSnqgCxWs51")
    //                             .collection("lessons-2023")
    //                             .doc(oldLesson.id)
    //                             .collection("lesson-groups")
    //                             .doc(oldGroup.id)
    //                             .collection("students")
    //                             .doc(doc.id),
    //                           oldStud,
    //                           { merge: true }
    //                         );
    //                       });
    //                     });
                    
                    
    //                 });
    //                 batch.commit().then(() => {
    //                   console.log("done!!!");
    //                 });
    //               });
    //           });
    //       });
    //     });
    // },
    async _rescueMovement(item) {
      if (item && item.TEACHER_ID) {
        var xx = await this.userData.school.ref
          .collection("teachers")
          .doc(item.TEACHER_ID.toString())
          .get();
        if (xx.exists) {
          this.selectedTeacher = xx.data();
          this.selectedTeacher.id = xx.id;
          this.selectedTeacher.ref = xx.ref;
          console.log(
            this.selectedTeacher,
            this._getMyClassInfo(this.selectedTeacher, "ACADEMIC_LEVEL")
          );

          this.userData.school.ref
            .collection("departments-" + this.school.currentYear)
            .doc(
              "department-" +
                this._getMyClassInfo(this.selectedTeacher, "ACADEMIC_LEVEL")
            )
            .collection("programs")
            .doc(
              this._getMyClassInfo(
                this.selectedTeacher,
                "STUDENT_GROUP_ID"
              ).toString()
            )
            .get()
            .then((doc) => {
              let prog = doc.data();
              prog.id = doc.id;
              prog.ref = doc.ref;
              prog.ref
                .collection("calendar-"+this.userData.school.currentYear+"-1")
                .get()
                .then((docs) => {
                  docs.docs.forEach((doc) => {
                    let cc = doc.data();
                    cc.id = doc.id;
                    cc.ref = doc.ref;
                    console.log(cc);
                    fb.db
                      .doc("schools/bSYE6D6U7DSnqgCxWs51")
                      .collection("departments-" + this.school.currentYear)
                      .doc(
                        "department-" +
                          this._getMyClassInfo(
                            this.selectedTeacher,
                            "ACADEMIC_LEVEL"
                          )
                      )
                      .collection("programs")
                      .doc(
                        this._getMyClassInfo(
                          this.selectedTeacher,
                          "STUDENT_GROUP_ID"
                        ).toString()
                      )
                      .collection("calendar-"+this.userData.school.currentYear+"-1")
                      .doc()
                      .set(cc, { merge: true });
                  });
                });
            });
        }
      } else {
        this.$swal.fire(
          "Багшийн мэдээлэл олдсонгүй. Админтай xолбоо барина уу."
        );
      }
    },
    _filteredClassGroups1() {
      var list = [];
      if (this.allPrograms) {
        var counter = 0;
        this.allPrograms.forEach((item) => {
          if (item._calendarSize > 0) {
            if(!item.deleted){
              counter++;
              item.index = counter;
              list.push(item);
            }
          }
        });
      }
      return list;
    },
    _filteredClassGroups2() {
      var list = [];
      if (this.allPrograms) {
        var counter = 0;
        this.allPrograms.forEach((item) => {
          if (!(item._calendarSize > 0)) {
            if(!item.deleted){
              counter++;
              item.index = counter;
              list.push(item);
            }
          }
        });
      }
      return list;
    },
    formatDate(val) {
      if (val != null) {
        if (!val) {
          return "-";
        }
        let date = val.toDate();
        return moment(date).fromNow();
      }
      return "-";
    },
    _print(item) {
      console.log(item.ref.path);
    },
    _closeDialog() {
      this.selectTeacherDialog = false;

      this.selectedProgram = null;
      this.selectedTeacher = null;
    },
    _calStudents() {
      this.departments.forEach((dep) => {
        dep.classgroups.forEach(async (progr) => {
          var studentRef = await this.school.ref
            .collection("students")
            .where("programs", "==", progr.ref)
            .get();
          if (!studentRef.empty) {
            progr.ref.update({ numberOfStudents: studentRef.size });
          } else {
            console.log(studentRef.size);
          }
        });
      });
    },
    async _setupp() {
      if (this.userData.school) {
        this.school = this.userData.school;
        this.selectedYear = this.school.currentYear;
        this._getDepartments(this.userData.school.currentYear);
      } else {
        this.school = null;
        this.selectedYear = null;
      }
      if (this.userData.ppschool != null) {
        fb.db
          .collection(this.userData.ppschool + "/teachers")
          .where("role", "==", "teacher")
          .where("deleted", "==", false)
          .orderBy("firstName", "asc")
          .get()
          .then((docs) => {
            this.teachers = [];
            this.teachers = [
              { name2: "<< Багшгүй >>", ref: null, value: "noteacher" },
            ];
            docs.docs.forEach((doc) => {
              let teacher = doc.data();
              teacher.id = doc.id;
              teacher.ref = doc.ref;
              this.teachers.push(teacher);
            });
          });
      }
    },
    _getDepartments(startYear) {
      this.numberOfAllGroups = 0;
      this.userData.school.ref
        .collection("departments-" + startYear)
        .orderBy("index", "desc")
        .onSnapshot((querySnapshot) => {
          this.departments = [];
          this.allPrograms = [];
          querySnapshot.forEach((doc) => {
            this.countOfPrograms = 0;
            let dep = doc.data();
            dep.id = doc.id;
            dep.ref = doc.ref;
            dep.classGroups = null;
            dep.ref
              .collection("programs")
              .orderBy("name", "asc")
              .onSnapshot((querySnapshot) => {
                dep.classGroups = [];
                dep.numberOfStudents = 0;
                querySnapshot.forEach(async (doc) => {
                  // doc.data() is never undefined for query doc snapshots
                  let prog = doc.data();
                  prog.id = doc.id;
                  prog.ref = doc.ref;
                  if (prog.fullName == undefined) {
                    prog.ref.update({ fullName: dep.name + prog.name });
                  }
                  if (
                    prog.numberOfStudents != undefined &&
                    prog.numberOfStudents != null
                  )
                    dep.numberOfStudents =
                      dep.numberOfStudents + prog.numberOfStudents;
                  prog.index = ++this.numberOfAllGroups;
                  prog.loading = false;
                  dep.classGroups.push(prog);
                  prog.implementationProcent = 10;
                  prog.ref
                    .collection("calendar-"+this.userData.school.currentYear+"-1")
                    .get()
                    .then((docs) => {
                      if (!docs.empty) {
                        prog.ref.update({ _calendarSize: docs.size });
                      } else {
                        prog.ref.update({ _calendarSize: 0 });
                      }
                    });
                  this.allPrograms.push(prog);
                });
              });
            this.departments.push(dep);
            // fb.db.collection("schools/"+this.schoolId+"/students").where("department", "==", doc.ref).get().then(stdocs=>{
            //   if(stdocs && !stdocs.empty){
            //     doc.ref.update({numberOfStudents: stdocs.size});
            //   }
            // });
          });
        });
    },
  },
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
    _filteredClassGroups() {
      var list = [];
      if (this.showOnlyWithCalendar) {
        list = this._filteredClassGroups1();
      } else if (this.showOnlyWithoutCalendar) {
        list = this._filteredClassGroups2();
      } else {
        if (this.allPrograms) {
          var counter = 0;
          this.allPrograms.forEach((item) => {
            if(!item.deleted){
              counter++;
              item.index = counter;
              list.push(item);
            }
          });
        }
      }
      return list;
    },

    filteredTeachers() {
      if (this.teachers) {
        var list = this.teachers.map((teacher, index) => {
          if (teacher["FIRST_NAME"])
            teacher.name2 = index + 1 + ". " + teacher["FIRST_NAME"];
          return teacher;
        });
        return list;
      } else return [];
    },
    countStudents: function () {
      var count = 0;
      if (this.departments) {
        this.departments.forEach((dep) => {
          count = count + dep.numberOfStudents;
        });
      }

      return count;
    },
  },
  created() {
    if (this.userData.school) this._setupp();
  },
};
</script>
<style>
[data-title2]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}
[data-title2]:after {
  content: attr(data-title2);
  position: absolute;
  bottom: -1.6em;
  left: 65%;
  padding: 4px 4px 4px 8px;
  color: #222;
  white-space: nowrap;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -moz-box-shadow: 0px 0px 4px #222;
  -webkit-box-shadow: 0px 0px 4px #222;
  box-shadow: 0px 0px 4px #222;
  background: yellow;
  color: black;
  opacity: 0;
  z-index: 99999;
  visibility: hidden;
}
[data-title2] {
  position: relative;
}
</style>
 